import './styles/app.scss';
import './styles/cookieconsent.scss';

import 'jquery'
// loads the Bootstrap plugins
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';

// loads the code syntax highlighting library
import './js/highlight.js';

// Creates links to the Symfony documentation
import './js/doclinks.js';

// start the Stimulus application
import 'bootstrap';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

import './js/flatpicker';

import '@popperjs/core';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import flatpickr from "flatpickr";

import Cookies from 'js.cookie';

import 'lightbox2';

$('#foodSwapper').click(function() {
    if($('#foodSwapper').is(":checked")){
        $('#menu-stable').toggle();
        $('#drink-stable').toggle();
    }
    else {
        $('#menu-stable').toggle();
        $('#drink-stable').toggle();
    }
});

$(document).ready(function () {
    //Cookies.remove('ageCheck');
    if((Cookies.get('ageCheck') == 'false')){
        $('#ageCheckFalse').modal('show');
    };

    if((Cookies.get('ageCheck') == undefined)) {
        $('#ageCheck').modal('show');
        $('#ageCheck .ageButton button').on('click', function(){
            // Extract info from data-bs-* attributes
            const check = $(this).data('bs-check')
            // If necessary, you could initiate an Ajax request here
            // and then do the updating in a callback.
            if(check == 1) {
                Cookies.set('ageCheck', 'true', { expires: 1 });
            } else {
                Cookies.set('ageCheck', 'false', { expires: 1 });
                $('#ageCheckFalse').modal('show');
            }
        });
    }

    var scroll = $(this).scrollTop();
    if (scroll > 0) {
        $('header').css({"background-color":"#fff"}, 300);
        $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
        $('header .nav-link').css({"color":"#000"}, 300);
    } else {
        $('header').css({"background-color":"#fff"}, 300);
        $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
        $('header .nav-link').css({"color":"#fff"}, 300);
    }

    $(document).scroll(function () {
        var scroll = $(this).scrollTop();
        if (scroll > 0) {
            $('header').css({"background-color":"#fff"}, 300);
            $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
            $('header .nav-link').css({"color":"#000"}, 300);
        } else {
            $('header').css({"background-color":"#fff"}, 300);
            $('.nav-container .white-lines .line').css({"background":"#000"}, 300);
            $('header .nav-link').css({"color":"#fff"}, 300);
        }
    });
});

const myCollapsible = document.getElementById('priceTable')
myCollapsible.addEventListener('shown.bs.collapse',function(args){
    var target = $(this).find('.collapse.show').attr('id');

    $('[data-bs-toggle="collapse-false"]').attr('data-bs-toggle', 'collapse');
    $('.food-navigation').removeClass('activeButton');
    $('button[data-bs-target="#'+target+'"]').attr('data-bs-toggle',  'collapse-false');
    $('button[data-bs-target="#'+target+'"]').addClass('activeButton');
});


flatpickr('#form_time', {
    enableTime: true,
    minDate: new Date().fp_incr(7),
    minTime: "08:00",
    maxTime: "17:00",
    dateFormat: "d.m.Y v H:i",
});

$('#reservation_date').flatpickr({
    dateFormat: "Y-m-d",
    time_24hr: true,
    allowInput: true,
});
$('#reservation_date_time').flatpickr({
    dateFormat: "H:i",
    enableTime: true,
    noCalendar: true,
    allowInput: true,
    minTime: "08:00",
    maxTime: "22:00",
    time_24hr: true,
    minuteIncrement: 15,
});

// ..
AOS.init();

$('#ageCheck').modal({
    keyboard: false,
    backdrop: 'static'
});

$('#ageCheckFalse').modal({
    keyboard: false,
    backdrop: 'static'
});

$(document).ready(function() {
    $('.minus').click(function () {
      var $input = $(this).parent().find('input');
      console.log($input.val());
      var count = parseInt($input.val()) - 1;
      count = count < 0 ? 0 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $('.plus').click(function () {
      var $input = $(this).parent().find('input');
      console.log($input.val());
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });

    var dataForForm = document.getElementById("exampleModalToggle");

    $('input#form_adult').change(function() {
        // form data 
        const modalBodyInput = dataForForm.querySelector('input#form_price');
        // text data
        const priceShow = dataForForm.querySelector('.finalprice')
        var helper = ($("input#form_adult").val()) * 160;
        //if($(".bigbagopt").val() != 0)
        //  var helperSec = 200 * $("input[name=counter]").val();
        //else
        var helperSec = ($("input#form_kids").val()) * 110;
    
        priceShow.textContent = `${helper + helperSec} Kč`;
        modalBodyInput.value = helper + helperSec
    });

    $('input#form_kids').change(function() {
        // form data 
        const modalBodyInput = dataForForm.querySelector('input#form_price');
        // text data
        const priceShow = dataForForm.querySelector('.finalprice')
        var helper = ($("input#form_kids").val()) * 110;
        //if($(".bigbagopt").val() != 0)
        //  var helperSec = 200 * $("input[name=counter]").val();
        //else
        var helperSec = ($("input#form_adult").val()) * 160;
    
        priceShow.textContent = `${helper + helperSec} Kč`;
        modalBodyInput.value = helper + helperSec
    });
});

var slider = tns({
    "container": '.my-slider',
    "controlsContainer": "#customize-controls",
    "autoWidth": true,
    "edgePadding": 50,
    "gutter": 10,
    "center": true,
    "mouseDrag": true,
    "swipeAngle": false,
    "controls": true,
    "nav": false,
    "speed": 400
});



